import { observer } from "mobx-react";
import Producto from "./Producto";
import productosStore from "../stores/ProductosStore";
import {Link} from "react-router-dom";

function ProductosOfertas() {
    if (productosStore.ofertas.length === 0)
        return;
    // Esto se carga solo en el home, cuando van al home, se pierde la seleccion de Lista
    productosStore.setListaID('');
    return (
        <div>
            <div className="row">
                <div className="col-12 text-center mb-3 ">
                    <h3 className="bold">Bajaron de Precio!</h3>
                    <h5 className="titulo-link"><Link to={'/catalogo'}>VER EL CATÁLOGO COMPLETO</Link></h5>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                { productosStore.ofertas.map(prod =>
                    <Producto prod={prod} key={prod.id} wide />
                )}
            </div>
        </div>
    );
}
export default observer(ProductosOfertas);