import Modal from "react-bootstrap/Modal";
import usuariosStore from "../stores/UsuariosStore";
import { useState } from "react";
import { observer } from "mobx-react";
import carritoStore from "../stores/CarritoStore";
import productosStore from "../stores/ProductosStore";
import sidebarStore from "./sidebar/SidebarStore";
import { cdnURL } from "../utils/api";
import '../css/bootstrap-grid.min.css';
import globals from "../globals";

const anchoCarrito = globals.anchoCarrito;

function currency(num, sign = '$') {
    if (!num) { return `$ 0.00` }
    if (typeof(num) == "string")
        num = parseFloat(num);
    return sign + ' ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function _Carrito(props) {
    const { confirmacion } = props;
    return (
        <div className={'carrito'}>
            { !confirmacion && <h1>PEDIDO</h1> }
            { confirmacion && <h1>CONFIRMAR PEDIDO</h1> }
            <div className={'carrito-productos'}>
                <div className={'row carrito-productos-header'}>
                    <div className={'desktop col-1'}></div>
                    <div className={'desktop col-1'}>CANT</div>
                    <div className={'desktop col-1'}>ID</div>
                    <div className={'desktop col-4'}>PRODUCTO</div>
                    <div className={'desktop col-2'}>PRECIO UNITARIO</div>
                    <div className={'desktop col-2'}>PRECIO TOTAL</div>
                    <div className={'desktop col-1'}></div>
                </div>

                <div className={'row carrito-productos-listado'}>
                    {carritoStore.carrito.map((p, idx) => (
                        <div className={'row'}>
                            <div className={'d-none d-sm-block col-1'}>
                                <img src={cdnURL(p.imagen, '/img/producto.png')} />
                            </div>
                            { confirmacion &&
                                <div className={'col-xl-1 col-lg-1 col-md-1 col-sm-2'}>
                                    <span className={'label-mobile mobile'}
                                          style={{marginTop: '7px'}}> CANT: &nbsp; </span>
                                    <span className={'carrito-productos-cant-confirmacion'}> {p.quantity} </span>
                                </div>
                            }
                            { !confirmacion &&
                                <div className={'col-xl-1 col-lg-1 col-md-1 col-sm-2'}>
                                    <span className={'label-mobile mobile'}
                                          style={{marginTop: '7px'}}> CANT: &nbsp; </span>
                                    <input
                                        className={'agregar-carrito-cant desktop'}
                                        type={'number'}
                                        step={1}
                                        min={1}
                                        value={p.quantity}
                                        onChange={e => carritoStore.setCantIdx(e.target.value, idx)}
                                    />
                                    <input
                                        className={'agregar-carrito-cant mobile'}
                                        type={'number'}
                                        step={1}
                                        min={1}
                                        value={p.quantity}
                                        style={{width: '50%'}}
                                        onChange={e => carritoStore.setCantIdx(e.target.value, idx)}
                                    />
                                </div>
                            }
                            <div className={'col-xl-1 col-lg-1 col-md-1 col-sm-2'}>
                                <span className={'label-mobile mobile'}> PROD ID: </span>
                                {p.prod_id}</div>
                            <div className={'col-xl-4 col-lg-4 col-md-4 col-sm-8'}>
                                <span className={'label-mobile mobile'}> PROD: </span>
                                {p.name}</div>
                            <div className={'col-xl-2 col-lg-2 col-md-2 col-sm-4'}>
                                <span className={'label-mobile mobile'}> PRECIO UNITARIO: </span>
                                {currency(p.price)}</div>
                            <div className={'col-xl-2 col-lg-2 col-md-2 col-sm-4'}>
                                <span className={'label-mobile mobile'}> PRECIO TOTAL: </span>
                                {currency(p.quantity * p.price)}</div>

                            {!confirmacion &&
                                <div className={'col-12 mobile'}>
                                    <button className={'boton-borrar-mobile'}
                                            onClick={() => carritoStore.borrarIdx(idx)}>
                                        BORRAR &nbsp;
                                        <i className="fa-solid fa-trash carrito-trash"/>
                                    </button>
                                </div>
                            }
                            {!confirmacion &&
                                <div className={'col-1 desktop'}>
                                    <i className="fa-solid fa-trash carrito-trash"
                                       onClick={() => carritoStore.borrarIdx(idx)}
                                    ></i>
                                </div>
                            }
                        </div>
                    ))}
                </div>

                <div className={'row carrito-productos-total'}>
                    <div className={'desktop col-2'}> TOTAL </div>
                    <div className={'desktop col-6 text-center'}>
                        {carritoStore.cant()} PRODUCTOS DISTINTOS CON {carritoStore.cantTotal()} ITEMS
                    </div>
                    <div className={'desktop col-4 text-right'}> {currency(carritoStore.total())} </div>
                </div>
            </div>

            { !confirmacion &&
                <div className={'row carrito-acciones'}>
                    <div className={'col-6'}>
                        <button onClick={() => sidebarStore.hide()}> Seguir Comprando</button>
                    </div>
                    <div className={'col-6'}>
                        <button className={'enviar'}
                                onClick={() => {
                                    sidebarStore.show(<Carrito confirmacion={true}/>, anchoCarrito)
                                }}
                        > Confirmar Pedido
                        </button>
                    </div>
                </div>
            }
            { confirmacion &&
                <div className={'carrito-productos'} style={{marginTop: '20px'}}>
                    <div className={'carrito-productos-header'}> COMENTARIO PARA EL VENDEDOR </div>
                    <textarea
                        rows={10}
                        className={'agregar-carrito-comentario'}
                        value={carritoStore.comentario}
                        onChange={e => carritoStore.setComentario(e.target.value)}
                    />
                </div>
            }
            { confirmacion &&
                <div className={'row carrito-acciones'}>
                    <div className={'col-6'}>
                        <button onClick={() => ShowCarrito()}> Volver </button>
                    </div>
                    <div className={'col-6'}>
                        <button className={'enviar'}
                                onClick={async () => {
                                    const [failed, msg] = await carritoStore.enviar();
                                    if (!failed) {
                                        sidebarStore.show(<CarritoEnviadoOK msg={msg} />, '40vw');
                                    } else {
                                        sidebarStore.show(<CarritoEnviadoERROR msg={msg} />, '40vw');
                                    }
                                }}
                        > Enviar Pedido
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}
const Carrito = observer(_Carrito);

function CarritoEnviadoOK(props) {
    return (
        <div className={'col-12 carrito-acciones text-center'}>
            Muchas gracias por su compra.<br /><br />
            El identificador de su orden es #{props.msg} <br /><br /><br />
            <button className={'enviar'} onClick={async () => sidebarStore.hide()}>
                Cerrar
            </button>
        </div>
    );
}

function CarritoEnviadoERROR(props) {
    return (
        <div className={'col-12 carrito-acciones text-center'}>
            Hubo un error generando la orden. ({props.msg})<br /><br />
            Vuelva a intentar en unos minutos o contactese con nosotros.<br /><br /><br />
            <button className={'enviar'} onClick={async () => ShowCarrito() }>
                Volver al Carrito
            </button>
        </div>
    );
}

function CarritoIcono() {
    return (
        <div className={'carrito-icono'} onClick={() => {
            ShowCarrito();
        }}>
            {usuariosStore.logged &&
                <i className="fa-solid fa-cart-shopping"></i>
            }
            {usuariosStore.logged && carritoStore.cant() > 0 &&
                <div className={'carrito-icono-cant'}>{carritoStore.cant()}</div>
            }
        </div>
    );
}
export default observer(CarritoIcono);

export function ShowCarrito() {
    //window.scrollTo(0, 0);
    sidebarStore.show(<Carrito />, anchoCarrito);
}