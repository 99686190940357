import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { observer } from "mobx-react";
import {Link, useParams} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import Buscador from "./Buscador";
import CategoriasSide from "./CategoriasSide";
import CategoriasBreadcrumbs from "./CategoriasBreadcrumbs";
import Producto from "./Producto";
import productosStore from "../stores/ProductosStore";
import Loading from "./Loading";
import { NoIVA } from "./Footer";
import CarouselMarcas from "./CarouselMarcas";
import usuariosStore from "../stores/UsuariosStore";
import ScrollToTop from "../utils/ScrollToTop";
import globals from "../globals";

function Catalogo(props) {
    const { query, catId, familia } = useParams();
    const { /*scroll = true,*/ simple, resXpag = 12, titulo, novedades } = props;
    const [state, setState] = useState({
        page: 0,
        pages: 0,
        runningPage: 0,
        productos: [],
        currentCatId: undefined,
        family: undefined,
        currentNovedades: novedades,
        cache: {}
    });

    const listar = (page) => {
        if (page !== 0 && page > state.pages) return;
        /*
        if (state.runningPage === state.page + 1) return;
        if (state.page !== 0 && state.page === state.pages) return;
         */
        // Para que no sea un scroll infinito y tenga paginacion
        window.scrollTo(0, 0);
        setState({ productos: [] });
        productosStore.listarProductos(
            novedades ? undefined : state.currentCatId,
            novedades ? undefined : state.family,
            novedades ? undefined : query,
            page,
            //state.page + 1,
            resXpag,
            novedades ? 'C' : 'A',
            novedades ? 'DESC' : 'ASC',
        //    novedades ? '' : undefined
        ).then(() => {
            if (!productosStore.errorListando) {
                setState({
                    page: productosStore.productos.page,
                    pages: productosStore.productos.pages,
                    runningPage: state.page + 1,
                    //productos: state.productos.concat(productosStore.productos.data),
                    productos: productosStore.productos.data,
                    currentCatId: state.currentCatId,
                    family: state.family,
                    currentNovedades: novedades
                });
            }
        });
    };

    const BotonCargarMas = () => {
        let items = [];
        // Vuelve 1
        if (state.page > 1) {
            items.push(
                <Pagination.Item key={'primero'} onClick={() => listar(1)}>
                    <i className="fa-solid fa-angle-double-left"></i>
                </Pagination.Item>
            );
            items.push(
                <Pagination.Item key={'unomenos'} onClick={() => listar(state.page - 1)}>
                    <i className="fa-solid fa-angle-left"></i>
                </Pagination.Item>
            );
        }

        const pags = isMobile ? 3 : 6;
        let resto = pags;
        if (state.page < pags + 1)
            resto += pags + 1 - state.page;
        for (
            let n = Math.max(1, state.page - pags);
            n <= Math.min(state.page + resto, state.pages);
            n++
        ) {
            items.push(
                <Pagination.Item key={n} active={n === state.page} onClick={() => listar(n)}> {n} </Pagination.Item>
            );
        }
        if (state.page < state.pages) {
            items.push(
                <Pagination.Item key={'unomas'} onClick={() => listar(state.page + 1)}>
                    <i className="fa-solid fa-angle-right"></i>
                </Pagination.Item>
            );
            items.push(
                <Pagination.Item key={'ultimo'} onClick={() => listar(state.pages)}>
                    <i className="fa-solid fa-angle-double-right"></i>
                </Pagination.Item>
            );
        }

        return (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 cargar-mas">
                {false && state.page > 0 && state.pages > 0 && <div> Pagina {state.page} de {state.pages} </div> }
                {false && <button onClick={() => listar()}> Cargar Mas Productos </button>}
                <Pagination> {items} </Pagination>
            </div>
        )
    }

    useEffect(() => {
        if (
            catId !== state.currentCatId ||
            familia !== state.family ||
            novedades !== state.currentNovedades
        ) {
            setState({
                page: 0,
                pages: 0,
                runningPage: 0,
                productos: [],
                currentCatId: catId,
                family: familia,
                currentNovedades: novedades
            });
        }
        if (!productosStore.errorListando && state.page === 0) {
            listar();
        }
        /*
        const handleScroll = (e) => {
            if (!scroll) return;
            const scrollHeight = e.target.documentElement.scrollHeight;
            const currentHeight = e.target.documentElement.scrollTop + window.innerHeight;
            if (currentHeight + 300 >= scrollHeight) {
                listar();
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
         */
    });

    const loadingCSS = {
        height: '100px',
        margin: '100px',
        display: productosStore.cargando ? "block" : "none"
    };

    if (productosStore.errorListandoNoAbort) {
        return (
            <div style={{marginTop: '140px'}}>
                <div className="row">
                    <div className="col-12 text-center mb-3 ">
                        <h3 className="bold">
                            El sitio se encuentra en mantenimiento.<br /><br />
                        </h3>
                        <h3>
                            Sepa disculpar las molestias, <br /> estaremos online a la brevedad.
                        </h3>
                    </div>
                </div>
            </div>
        )
    }

    if (simple) {
        return (
            <div>
                <div className="row">
                    <div className="col-12 text-center mb-3 ">
                        <h3 className="bold">{titulo}</h3>
                        <h5 className="titulo-link"><Link to={'/catalogo'}>VER EL CATÁLOGO COMPLETO</Link></h5>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    { !productosStore.cargando && state.productos.length === 0 &&
                        <span>No hay productos para mostrar.</span>
                    }
                    { state.productos.map(prod =>
                        <Producto prod={prod} key={prod.id} wide />
                    )}
                </div>
                <div style={loadingCSS}> <Loading /> </div>
                <NoIVA />
            </div>
        )
    }

    const Download = (props) => {
        const { id, filename, extension } = props;
        const [loading, setLoading] = useState(false);
        const [fecha, setFecha] = useState('');

        const getDate = (date) => {
            const url = globals.OPEN + '/api/archivos/updated/' + id;
            fetch(url)
                .then(response => response.text())
                .then(txt => {
                    setFecha(txt);
                }).catch((err) => {
                    console.error(err);
                });
        }
        if (!fecha) getDate(id);

        const dwnFile = (date) => {
            const url = globals.OPEN + '/cdn/archivos/' + id + '.' + extension;
            setLoading(true);
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    const d = new Date();
                    //const date = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = filename + '_' + date + '.' + extension;
                    link.click();
                    setLoading(false);
                }).catch((err) => {
                setLoading(false);
                console.error(err);
            });
        }
        const dwn = () => {
            const url = globals.OPEN + '/api/archivos/updated/' + id;
            fetch(url)
                .then(response =>
                    response.text()
                        .then(response => {
                            const fecha = response.split(' ')[0]
                                .replaceAll(' ', '_')
                                .replaceAll('/', '-');
                            dwnFile(fecha);
                        })
                )
        }
        return (
            <a type="submit" className="" onClick={() => dwn()} style={{textAlign: 'center'}}>
                {loading && <Loading size={'27'} /> }
                {!loading && extension==='xls' && <i className="fa-solid fa-file-excel" style={{fontSize: '27px'}}></i> }
                {!loading && extension==='pdf' && <i className="fa-solid fa-file-pdf" style={{fontSize: '27px'}}></i> }
                <div style={{
                    fontSize: '0.7em',
                    lineHeight: '10px'
                }}> {fecha} </div>
            </a>
        )
    }

    return (
        <div className="container-fluid px-0 mx-0" id="catalogo">
            <ScrollToTop />
            <div className="container">
                <CarouselMarcas />
                <div className="row d-flex justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 bc-bg">
                        <CategoriasBreadcrumbs />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
                        <CategoriasSide novedades={novedades} />
                    </div>
                    <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12 lista-productos">
                        <div className="row my-lg-4 mt-md-0 mb-md-4 mt-sm-0 mb-sm-4 mt-0 mb-4">
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 descargar">
                                {(usuariosStore.logged) &&
                                    <button className={'btn btn-descargar'} onClick={() =>
                                        productosStore.setShowDownloadModal(!productosStore.showDownloadModal)
                                    }>
                                        Descargá el catalogo <i className="fa-solid fa-download"></i>
                                    </button>
                                }
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 buscador-catalogo">
                                <Buscador />
                            </div>
                        </div>

                        <div className="row listado-angosto">
                            { !productosStore.cargando && state.productos.length === 0 &&
                                <span>No hay productos para mostrar.</span>
                            }
                            { state.productos.map(prod =>
                                <Producto prod={prod} key={prod.id} />
                            )}
                        </div>

                        <div style={loadingCSS}> <Loading /> </div>
                        <BotonCargarMas />
                    </div>
                </div>
            </div>
            <NoIVA />

            <Modal
                show={productosStore.showDownloadModal}
                onHide={() => productosStore.setShowDownloadModal(false)}
                backdrop={'static'}
                keyboard={false}
                className="descarga-catalogo"
            >
                <Modal.Header>
                    <h4 className="modal-title " id="">DESCARGAR EL CATALOGO DE PRODUCTOS</h4>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() =>
                        productosStore.setShowDownloadModal(false)
                    }></button>
                </Modal.Header>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12" style={{textAlign: 'center'}}>
                            <h4>DISTRICENTRO FERRETERA</h4>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <table className={'table'}>
                                <tr>
                                    <td> CATALOGO DE PRODUCTOS </td>
                                    <td className={'file'}>
                                        <Download
                                            id={3}
                                            url='/cdn/archivos/3.xls'
                                            filename='CatalogoDistricentro'
                                            extension='xls'
                                        />
                                    </td>
                                    <td className={'file'}>
                                        <Download
                                            id={4}
                                            url='/cdn/archivos/4.pdf'
                                            filename='CatalogoDistricentro'
                                            extension='pdf'
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td> ULTIMAS MODIFICACIONES </td>
                                    <td className={'file'}>
                                        <Download
                                            id={11}
                                            url='/cdn/archivos/11.xls'
                                            filename='ModificacionesDistricentro'
                                            extension='xls'
                                        />
                                    </td>
                                    <td className={'file'}>
                                        <Download
                                            id={12}
                                            url='/cdn/archivos/12.pdf'
                                            filename='ModificacionesDistricentro'
                                            extension='pdf'
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12" style={{textAlign: 'center'}}>
                            <h4>LEONARDO</h4>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <table className={'table'}>
                                <tr>
                                    <td> CATALOGO DE PRODUCTOS </td>
                                    <td className={'file'}>
                                        <Download
                                            id={2}
                                            url='/cdn/archivos/2.xls'
                                            filename='CatalogoLeonardo'
                                            extension='xls'
                                        />
                                    </td>
                                    <td className={'file'}>
                                        <Download
                                            id={1}
                                            url='/cdn/archivos/1.pdf'
                                            filename='CatalogoLeonardo'
                                            extension='pdf'
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td> CATALOGO DE PRODUCTOS CON FOTOS </td>
                                    <td className={'file'}>
                                        <Download
                                            id={14}
                                            url='/cdn/archivos/14.xls'
                                            filename='CatalogoLeonardoConFotos'
                                            extension='xls'
                                        />
                                    </td>
                                    <td className={'file'}> </td>
                                </tr>
                                <tr>
                                    <td> ULTIMAS MODIFICACIONES </td>
                                    <td className={'file'}>
                                        <Download
                                            id={13}
                                            url='https://districentro.openplus.net.ar/cdn/archivos/13.xls'
                                            filename='ModificacionesLeonardo'
                                            extension='xls'
                                        />
                                    </td>
                                    <td className={'file'}> </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default observer(Catalogo);
